<template>
  <div style="padding:2rem">
    <v-container>
      <v-form
        ref="form"
        lazy-validation>
        <v-row v-if="labels">
          <v-col cols="12" sm="6">
            <div class="spacer"></div>
            <table>
              <tr>
                <td class="picto">
                  <img src="@/assets/pictos/bord-cote.png"/>
                </td>
                <td>
                  <v-radio-group
                    v-model="emplacement"
                    :rules="requireRules"
                  >
                    <v-radio
                      v-for="(label, index) in labels"
                      :key="index"
                      :label="label.label"
                      :value="label.value"
                      @click="getPrice('option')"
                    />
                  </v-radio-group>
                </td>
              </tr>
            </table>
            <p>
              <em>
                Nous remplaçons les bords cotes abîmés par de nouveaux dans un coloris identique ou
                rapprochant
              </em>
            </p>
          </v-col>
          <v-col cols="12">
            <image-preview
              :is-bonus-eligible="bonus > 0"
              :value="pictures"
              @change="v => pictures = v"
            />
          </v-col>
          <price :amount="price" :bonus="bonus" :display-bottom-line="!!emplacement"/>
        </v-row>
        <bonus-image-confirm
          v-if="bonus > 0 && pictures.length < 2"
          @skip-bonus="bonus = 0"
        />
        <confirm-flow
          v-else-if="emplacement && !this.flux.indexEditProduit"
          @finalize="noarticle"
          @new-repair="onSelectNewRepair"
          @new-article="noarticle(false)"
        />
        <v-row v-else-if="this.flux.indexEditProduit">
          <v-col class="text-center" cols="12">
            <div class="btn btn-1 text-center btn-size" @click="noarticle">MODIFIER</div>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
    <span style="display:none">{{ flux.actionId }}</span>
  </div>
</template>
<script>
import {mapMutations, mapState} from 'vuex'
import zipPrice from '../../assets/data/price/bord-cote.json';
import Price from "@/components/shared/Price.vue";
import ImagePreview from "@/components/interface/ImagePreview.vue";
import BonusImageConfirm from "@/components/shared/BonusImageConfirm.vue";
import ConfirmFlow from "@/components/shared/ConfirmFlow.vue";

let liste_reparations = []

export default {
  data() {
    return {
      emplacement: null,
      requireRules: [
        v => !!v || 'Ce champs est requis'
      ],
      poche_emplacement: null,
      autre_emplacement: null,
      isModalVisible: false,
      isCursor: false,
      price: 0,
      bonus: 0,
      labels: [],
      oneUpdate: false,
      description_manager: null,
      pictures: []
    }
  },
  created() {
    if (this.flux.activeTab !== 'bord-cote') {
      return null;
    }
    this.labels = zipPrice[0][this.flux.level2].labels
    //Recup valeur champ si mode Edition
    if (this.flux.indexEditProduit !== null) {
      this.emplacement = this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation]['emplacement']
      this.price = this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation]['price']
      this.bonus = this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation]?.bonus?.[this.flux.isDouble ? 'double' : 'non_double'] ?? 0
    }
  },
  updated() {
    if (this.flux.activeTab !== 'bord-cote') {
      return null;
    }
    //Recup valeur champ si mode Edition
    if (this.flux.level2 && this.flux.verrou === false && this.flux.indexEditProduit !== null) {
      this.setVerrou(true)
      this.labels = zipPrice[0][this.flux.level2].labels
      this.emplacement = this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation]['emplacement']
      this.price = this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation]['price']
      this.bonus = this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation]?.bonus?.[this.flux.isDouble ? 'double' : 'non_double'] ?? 0
    }
  },
  computed: {
    ...mapState('cart-app', ['cards', 'products']),
    ...mapState('flux-app', ['flux']),
  },
  methods: {
    ...mapMutations('flux-app', ['setConstat', 'setcptProduct', 'setcptReparation', 'setVerrou', 'setLevel1', 'setLevel2', 'setLevel3', 'setComponentName']),
    ...mapMutations('cart-app', ['addProduct', 'getTotalCart']),
    editRepair(indexes) {
      this.$emit('edit', indexes)
    },
    evenement(value) {
      if (value == 'scroll3') {
        this.$scrollTo('#screen3')
      }
      if (value == 'scroll1') {
        this.$scrollTo('#screen1')
      }
    },
    noarticle(showCart = true) {
      if (this.$refs.form.validate()) {
        if (this.flux.indexEditProduit !== null) { //Mode Edit
          this.editProduct();
          this.setLevel1(null)
          this.setLevel2(null)
          this.setLevel3(null)
          this.setComponentName(null)
        } else {
          this.storeProduct()
          this.setcptProduct(this.flux.cptProduct + 1)
          liste_reparations = []
          this.setcptReparation(0)
        }

        this.$emit('evenement', 'scroll1')

        if (showCart) {
          this.showModal('cart')
        }
      }
    },
    onSelectNewRepair() {
      this.storeProduct()
      this.$emit('evenement', 'scroll3')
    },
    editProduct() {
      liste_reparations = this.products[this.flux.indexEditProduit].reparations
      //On check l'historique des réparations
      let index = liste_reparations.findIndex((obj => obj.id == (this.flux.indexEditReparation + 1)));
      if (index > -1) {
        let reparation = {
          id: liste_reparations[index].id,
          type: 'Bord cote',
          componentName: this.flux.level3,
          activeTab: 'bord-cote',
          emplacement: this.emplacement,
          qte: 1,
          price: this.price,
          bonus: this.pictures.length > 1 ? this.bonus : null,
          description: this.getDescription(),
          pictures: this.pictures
        }

        this.products[this.flux.indexEditProduit].reparations.splice(index, 1, reparation)

        let data = {
          idLevel1: this.products[this.flux.indexEditProduit].idLevel1,
          level1: this.products[this.flux.indexEditProduit].level1,
          level2: this.products[this.flux.indexEditProduit].level2,
          level3: this.products[this.flux.indexEditProduit].level3,
          isDouble: this.products[this.flux.indexEditProduit].isDouble,
          id: this.products[this.flux.indexEditProduit].id,
          name: this.products[this.flux.indexEditProduit].name,
          famille: this.products[this.flux.indexEditProduit].famille,
          reparations: liste_reparations,
        }


        this.addProduct(data)
        this.getTotalCart(this.products)


        this.$emit('evenement', 'scroll3')

      }
    },
    storeProduct() {
      if (this.$refs.form.validate()) {
        this.setcptReparation(this.flux.cptReparation + 1)

        if (this.flux.cptProduct < 1) {
          this.setcptProduct(this.flux.cptProduct + 1)
        }

        //On check l'historique des réparations
        liste_reparations = []
        let index = this.products.findIndex((obj => obj.id == this.flux.cptProduct));
        if (index > -1) {
          liste_reparations = this.products[index].reparations
        }

        let reparation = {
          id: this.flux.cptReparation,
          type: 'Bord cote',
          componentName: this.flux.level3,
          activeTab: 'bord-cote',
          emplacement: this.emplacement,
          qte: 1,
          price: this.price,
          bonus: this.pictures.length > 1 ? this.bonus : null,
          description: this.getDescription(),
          pictures: this.pictures
        }

        liste_reparations.push(reparation)

        let data = {
          idLevel1: this.flux.idLevel1,
          level1: this.flux.level1,
          level2: this.flux.level2,
          level3: this.flux.level3,
          isDouble: this.flux.isDouble,
          id: this.flux.cptProduct,
          name: this.flux.level2,
          famille: this.flux.level1,
          reparations: liste_reparations
        }

        this.addProduct(data)
        this.getTotalCart(this.products)
        this.setLevel3(null)
        this.setComponentName(null)
      }
    },
    showModal(constat) {
      this.setConstat(constat)
      this.$emit('showmodal', this.flux.level2)
    },
    closeModal() {
      this.isModalVisible = false;
    },
    getPrice(param) {
      if (param !== undefined) {
        this.isCursor = false
      }
      if (this.isCursor === true) {
        this.price = zipPrice[1]['curseur']
      } else {
        this.price = zipPrice[0][this.flux.level2][this.emplacement]
        this.bonus = zipPrice[0][this.flux.level2]?.bonus?.[this.emplacement]?.[this.flux.isDouble ? 'double' : 'non_double'] ?? 0

        if (this.flux.isDouble) {
          this.price = this.price * 1.2
        }
      }

      this.price = Math.round(this.price * 100) / 100

    },
    getDescription() {
      let description_manager = ''
      description_manager += '<b>Emplacement</b> : ' + this.emplacement + '<br />';
      if (this.autre_emplacement && this.emplacement === 'autre') {
        description_manager += '<b>Précision</b> : ' + this.autre_emplacement + '<br />'
      }
      if (this.poche_emplacement && this.emplacement === 'poche') {
        description_manager += '<b>Précision</b> : ' + this.poche_emplacement + '<br />'
      }
      description_manager += '<b>Qté</b> : 1<br />';
      return description_manager
    }
  },
  components: {
    ConfirmFlow,
    BonusImageConfirm,
    ImagePreview,
    Price
    // Modal
  }
}
</script>
<style scoped>
table {
  max-width: 390px;
}

table td.picto, table td.picto img {
  width: 100px;
}

table td.picto {
  padding: 20px 20px 20px 0;
}

.btn {
  background-color: #000;
  width: 120px;
  padding: 10px;
  color: #fff
}

.grey {
  background-color: #F8F8F8 !important;
}
</style>
